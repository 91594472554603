import {
  Grid,
  Container,
  Card,
  CardMedia,
  CardContent,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { PgColors } from "../../configs/colors";

export const CardLanding4 = () => {
  const { t } = useTranslation();

  return (
    <Card sx={{ height: { sm: 225, md: 375, lg: 350, xl: 300 } }}>
      {/* <CardMedia /> */}
      <CardContent>
        <Grid container display="flex" justifyContent="center" mt={1} mb={1}>
          <Grid item xs={3}>
            <ArticleOutlinedIcon
              sx={{ color: PgColors.bluepg[200] }}
              fontSize="large"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} mb={1}>
          <Typography
            variant="h5"
            style={{ color: PgColors.redpg[200], fontWeight: 500 }}
            sx={{ fontSize: { xs: 16, md: 20, lg: 24 } }}
          >
            {t("CardLanding.Title4")}
          </Typography>
        </Grid>
        <Typography variant="body1">{t("CardLanding.Text4")}</Typography>
      </CardContent>
    </Card>
  );
};
