import { useState } from "react";
import { auth } from "../firebase/config";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useAuthContext } from "./useAuthContext";

export const useLogin = () => {
  const [error, setError] = useState("");
  const { dispatch } = useAuthContext();

  const login = async (email: string, password: string): Promise<boolean> => {
    setError("");
    try {
      const res = await signInWithEmailAndPassword(auth, email, password);
      dispatch({ type: "LOGIN", payload: res.user });
      return true;
    } catch (err: any) {
      setError(err.message);
      return false;
    }
  };

  return { error, login };
};
