import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, getIdTokenResult } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDDvPIu3HqMjD9ybIfP7OXX-7V-A68Uocw",
  authDomain: "pg-coach.firebaseapp.com",
  projectId: "pg-coach",
  storageBucket: "pg-coach.appspot.com",
  messagingSenderId: "1029424100441",
  appId: "1:1029424100441:web:037c1650541923c021ed48",
};

// init firebase
initializeApp(firebaseConfig);

// init firestore
const db = getFirestore();

// init storage
const storage = getStorage();

// init firebase auth
const auth = getAuth();

export { db, auth, storage };
