import { createContext, useEffect, useReducer, useState } from "react";
import React, { ReactNode } from "react";
import { auth } from "../firebase/config";
import { onAuthStateChanged } from "firebase/auth";


export type Dispatch = (action: any) => void;
type User = {
  email: string;
  password: string;
  displayName: string;
  uid: string;
  username: string;
};

type AdminUser = {
  email: string;
  password: string;
  displayName: string;
  uid: string;
  username: string;
  admin: boolean;
};

type PremiumUser = {
  email: string;
  password: string;
  displayName: string;
  uid: string;
  username: string;
  stripeRole: boolean;
};



export const AuthContext = createContext<
  | {
      dispatch: Dispatch;
      user: User;
      adminUser?: AdminUser;
      premiumUser?: PremiumUser;
      authIsReady: boolean;
    }
  | undefined
>(undefined);

interface IProps {
  children: React.ReactNode;
}

export const authReducer = (state: any, action: any) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, user: action.payload };
    case "LOGOUT":
      return {
        ...state,
        user: null,
        // adminUser: undefined,
        // premiumUser: undefined,
      };
    case "AUTH_IS_READY":
      return {
        ...state,
        user: action.payload,
        // adminUser: action.payload.adminUser,
        // premiumUser: action.payload.premiumUser,
        authIsReady: true,
      };
    default:
      return state;
  }
};

export const AuthContextProvider: React.FunctionComponent<IProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    // adminUser: undefined,
    // premiumUser: undefined,
    authIsReady: false,
  });

  // useEffect(() => {
  //   const setupAuthStateListener = async () => {
  //     try {
  //       const user = auth.currentUser;
  //       if (user) {
  //         const idTokenResult = await user.getIdTokenResult();
  //         // console.log(idTokenResult.claims);
  //         const adminUser = idTokenResult.claims.admin;
  //         const premiumUser = idTokenResult.claims.stripeRole;
  //         dispatch({
  //           type: "AUTH_IS_READY",
  //           payload: { user, adminUser, premiumUser },
  //         });
  //       } else {
  //         dispatch({
  //           type: "AUTH_IS_READY",
  //           payload: { user, adminUser: undefined, premiumUser: undefined },
  //         });
  //       }
  //     } catch (error) {
  //       console.error("Error fetching user token:", error);
  //     }
  //   };
  //   const unsub = onAuthStateChanged(auth, setupAuthStateListener);
  //   return () => unsub();
  // }, []);

  // useEffect(() => {
  //   const setupAuthStateListener = async () => {
  //     try {
  //       const user = auth.currentUser;
  //       if (user) {
  //         const idTokenResult = await user.getIdTokenResult();
  //         const adminUser = idTokenResult.claims.admin;
  //         const premiumUser = idTokenResult.claims.stripeRole;
  //         dispatch({
  //           type: "AUTH_IS_READY",
  //           payload: { user, adminUser, premiumUser },
  //         });
  //       } else {
  //         dispatch({
  //           type: "AUTH_IS_READY",
  //           payload: {
  //             user: null,
  //             adminUser: undefined,
  //             premiumUser: undefined,
  //           },
  //         });
  //       }
  //     } catch (error) {
  //       console.error("Error fetching user token:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   const unsub = onAuthStateChanged(auth, setupAuthStateListener);
  //   setupAuthStateListener(); // Call the function immediately to ensure initial setup
  //   return () => unsub();
  // }, []);

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  // useEffect(() => {
  //   const setupAuthStateListener = async () => {
  //     try {
  //       const user = auth.currentUser;
  //       let adminUser = false;
  //       let premiumUser = false;

  //       if (user) {
  //         const idTokenResult = await user.getIdTokenResult();
  //         adminUser = idTokenResult.claims.admin || false;
  //         premiumUser = idTokenResult.claims.stripeRole || false;
  //       }

  //       dispatch({
  //         type: "AUTH_IS_READY",
  //         payload: {
  //           user,
  //           adminUser,
  //           premiumUser,
  //         },
  //       });
  //     } catch (error) {
  //       console.error("Error fetching user token:", error);
  //     }
  //   };

  //   const unsub = onAuthStateChanged(auth, setupAuthStateListener);
  //   setupAuthStateListener(); // Call the function immediately to ensure initial setup
  //   return () => unsub();
  // }, []);

  // useEffect(() => {
  //   const unsub = onAuthStateChanged(auth, async (user) => {
  //     if (user) {
  //       try {
  //         const idTokenResult = await user.getIdTokenResult();
  //         const adminUser = idTokenResult.claims.admin || false;
  //         const premiumUser = idTokenResult.claims.stripeRole || false;

  //         dispatch({
  //           type: "AUTH_IS_READY",
  //           payload: {
  //             user,
  //             adminUser,
  //             premiumUser,
  //           },
  //         });
  //       } catch (error) {
  //         console.error("Error fetching user token:", error);
  //       }
  //     } else {
  //       dispatch({ type: "AUTH_IS_READY", payload: null }); // Reset the user data
  //     }
  //   });

  //   return () => unsub();
  // }, []);

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      dispatch({ type: "AUTH_IS_READY", payload: user });
      unsub();
    });
  }, []);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {/* {state.authIsReady ? children : <p>Loading authentication...</p>} */}
      {children}
    </AuthContext.Provider>
  );
};
