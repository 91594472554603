import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import { Grid } from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { AddDScore2 } from "../D-Score/AddDScore2";
import { AddDScoreGymnast } from "./AddDScoreGymnast";
import { DScoresListGymnast } from "./DScoresListGymnast";
import { AddDScoreCoach } from "./AddDScoreCoach";
import { DScoresListCoach } from "./DScoresListCoach";


export const DScorePageCoach = () => {
  const { authIsReady, user } = useAuthContext();
  const { id } = useParams();
  const [dScoresList, setDScoresList] = useState([]);

  useEffect(() => {
    if (authIsReady && user) {
      const ref = query(
        collection(db, `MDScore/${id}/userDScore`),
        orderBy("createdAt", "desc")
      );

      const unsub = onSnapshot(ref, (snapshot) => {
        let results: any = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });
        setDScoresList(results);
      });
      return () => unsub();
    }
  }, [authIsReady, user]);

  if (!authIsReady) {
    return <p>Loading...</p>;
  }


  return (
    <>
      <Grid container mt={2}>
        <AddDScoreCoach />
      </Grid>
      <Grid container mt={4}>
        {dScoresList && <DScoresListCoach dScores={dScoresList} />}
      </Grid>
    </>
  );
};
