import { Grid, Typography, Container, Button, Box } from "@mui/material";
import { CardLanding } from "../components/landingPage/CardLanding";
import { Header } from "../components/landingPage/Header";
import { PgColors } from "../configs/colors";
import { useTranslation } from "react-i18next";
import { CardLanding2 } from "../components/landingPage/CardLanding2";
import { CardLanding3 } from "../components/landingPage/CardLanding3";
import { CardLanding4 } from "../components/landingPage/CardLanding4";
import InstagramIcon from "@mui/icons-material/Instagram";
import { NavLink } from "react-router-dom";

export const LandingPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <Box
        sx={{
          backgroundColor: PgColors.greenpg[100],
          borderBottom: 2,

          borderColor: "#000000",
        }}
      >
        <Container maxWidth={"xl"}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={5}
              sx={{ marginTop: { xs: 6, md: 16, lg: 26, xl: 34 } }}
            >
              <Typography
                variant="h3"
                style={{ color: PgColors.redpg[200] }}
                sx={{ fontSize: { xs: 24, md: 40, lg: 50 } }}
              >
                {t("Landing.Title1")}
              </Typography>
            </Grid>
            <Grid item md={2}></Grid>
            <Grid
              item
              md={5}
              sx={{
                marginLeft: { md: -10, lg: -12, xl: -16 },
                marginTop: { md: 16, lg: 18 },
              }}
              display={{ xs: "none", md: "flex" }}
            >
              <Box
                component="img"
                alt="symbol2"
                src="../images/PGLanding1.png"
                sx={{ maxHeight: { md: 350, lg: 450, xl: 600 } }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                marginTop: { xs: 4, md: -24, lg: -28, xl: -40 },
                marginBottom: { xs: 6, md: 0, lg: 0 },
              }}
            >
              <Typography variant="body1">{t("Landing.Text1")}</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              marginTop={6}
              marginBottom={8}
              display={{ xs: "none", md: "flex" }}
            >
              {/* <Button variant="contained" color="secondary" size="large">
                <Typography variant="body1">LEARN MORE</Typography>
              </Button> */}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: PgColors.pinkpg[100],
          borderBottom: 2,
          borderColor: "#000000",
        }}
      >
        <Container maxWidth={"xl"}>
          <Grid container direction="row" justifyContent="center">
            <Grid
              item
              md={4}
              // direction="row"
              justifyContent="center"
              sx={{ marginTop: { xs: 6, md: 14, lg: 20 } }}
            >
              <Typography
                variant="h3"
                style={{ color: PgColors.bluepg[200] }}
                sx={{ fontSize: { xs: 24, md: 40, lg: 50 } }}
              >
                {t("Landing.Title2")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginBottom: { xs: 8, md: 20 } }}>
            <Grid item xs={12} md={3} sx={{ marginTop: { xs: 6, md: 10 } }}>
              <CardLanding />
            </Grid>
            <Grid item xs={12} md={3} sx={{ marginTop: { xs: 6, md: 10 } }}>
              <CardLanding2 />
            </Grid>
            <Grid item xs={12} md={3} sx={{ marginTop: { xs: 6, md: 10 } }}>
              <CardLanding3 />
            </Grid>
            <Grid item xs={12} md={3} sx={{ marginTop: { xs: 6, md: 10 } }}>
              <CardLanding4 />
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* <Box
        sx={{
          backgroundColor: PgColors.bluepg[300],
          borderBottom: 2,
          borderColor: "#000000",
        }}
      >
        <Container maxWidth={"xl"}>
          <Grid container spacing={4}>
            <Grid item md={6} marginTop={20} marginBottom={20}>
              <CardLanding />
            </Grid>
            <Grid item md={6} marginTop={20}>
              <CardLanding />
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item marginTop={6} marginBottom={20}>
              <Button variant="contained" color="secondary" size="large">
                <Typography variant="body1">
                  READ CLIENT SUCCESS STORIES
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box> */}
      <Box
        sx={{
          backgroundColor: PgColors.bluepg[100],
          borderBottom: 2,

          borderColor: "#000000",
        }}
      >
        <Container maxWidth={"xl"}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                marginTop: { xs: 6, md: 36, lg: 36, xl: 40 },
                fontSize: { xs: 24, md: 40, lg: 50 },
              }}
            >
              <Typography
                variant="h3"
                style={{ color: PgColors.redpg[200] }}
                sx={{ fontSize: { xs: 24, md: 40, lg: 50 } }}
              >
                {t("Landing.Title3")}
              </Typography>
            </Grid>
            <Grid item md={2}></Grid>
            <Grid
              item
              md={5}
              sx={{
                marginLeft: { md: 0 },
                marginTop: { md: 16 },
              }}
              display={{ xs: "none", md: "flex" }}
            >
              <Box
                component="img"
                alt="symbol2"
                src="../images/PGLanding2.png"
                sx={{ maxHeight: { md: 600 } }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={5} sx={{ marginTop: { xs: 6, md: -35 } }}>
              <Typography variant="body1">{t("Landing.Text3")}</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item marginTop={6} sx={{ marginBottom: { xs: 2, md: 14 } }}>
              {/* <Button variant="contained" color="secondary" size="large">
                <Typography variant="body1">LEARN MORE</Typography>
              </Button> */}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: PgColors.redpg[50],
          borderBottom: 2,
          borderColor: "#000000",
        }}
      >
        <Container maxWidth={"xl"}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            display={{ xs: "none", md: "flex" }}
          >
            <Grid item lg={1} display={{ xs: "none", lg: "flex" }}></Grid>
            <Grid item xs={3} lg={3} marginTop={10} justifyContent="center">
              <Typography variant="h6" style={{ color: PgColors.bluepg[300] }}>
                Contact
              </Typography>
            </Grid>
            <Grid item lg={1} display={{ xs: "none", lg: "flex" }}></Grid>
            <Grid item xs={4} lg={3} marginTop={10} justifyContent="center">
              <Typography variant="h6" style={{ color: PgColors.bluepg[300] }}>
                Belangrijk
              </Typography>
            </Grid>
            <Grid item lg={1} display={{ xs: "none", lg: "flex" }}></Grid>
            <Grid item xs={4} lg={3} marginTop={10} justifyContent="center">
              <Typography variant="h6" style={{ color: PgColors.bluepg[300] }}>
                Social Media
              </Typography>
            </Grid>
          </Grid>
          <Grid container marginTop={2} display={{ xs: "none", lg: "flex" }}>
            <Grid item lg={1} display={{ xs: "none", lg: "flex" }}></Grid>
            <Grid item xs={4} lg={3} justifyContent="center">
              <Typography
                variant="subtitle1"
                style={{ color: PgColors.bluepg[300] }}
                // sx={{ fontSize: { xs: 14, md: 20 } }}
              >
                info@personalgymnastics.com
              </Typography>
            </Grid>
            <Grid item lg={1} display={{ xs: "none", lg: "flex" }}></Grid>
            <Grid item xs={4} lg={3} justifyContent="center">
              <NavLink
                className="nav-link"
                to="/terms"
                style={{ textDecoration: "none" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: PgColors.bluepg[300] }}
                >
                  Algemene Voorwaarden
                </Typography>
              </NavLink>
            </Grid>
            <Grid item lg={1} display={{ xs: "none", lg: "flex" }}></Grid>
            <Grid item xs={4} lg={3} justifyContent="center">
              <a
                href="https://www.instagram.com/personal_gymnastics/"
                target="_blank"
              >
                <InstagramIcon sx={{ color: PgColors.bluepg[300] }} />
              </a>
            </Grid>
          </Grid>
          <Grid
            container
            marginTop={0}
            marginBottom={12}
            display={{ xs: "none", lg: "flex" }}
          >
            <Grid item lg={1} display={{ xs: "none", lg: "flex" }}></Grid>
            <Grid item xs={4} lg={3} justifyContent="center"></Grid>
            <Grid item lg={1} display={{ xs: "none", lg: "flex" }}></Grid>
            <Grid item xs={4} lg={3} justifyContent="center">
              {/* <NavLink
                className="nav-link"
                to="/terms"
                style={{ textDecoration: "none" }}
              > */}
              <Typography
                variant="subtitle1"
                style={{ color: PgColors.bluepg[300] }}
              >
                Opzeggen
              </Typography>
              {/* </NavLink> */}
            </Grid>
          </Grid>
          <Grid
            container
            marginTop={0}
            marginBottom={4}
            display={{ xs: "flex", md: "none" }}
          >
            <Grid item xs={12} marginTop={4}>
              <Typography variant="h6" style={{ color: PgColors.bluepg[300] }}>
                Contact
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                style={{ color: PgColors.bluepg[300] }}
                // sx={{ fontSize: { xs: 14, md: 20 } }}
              >
                info@personalgymnastics.com
              </Typography>
            </Grid>
            <Grid item xs={12} marginTop={4}>
              <Typography variant="h6" style={{ color: PgColors.bluepg[300] }}>
                Belangrijk
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <NavLink
                className="nav-link"
                to="/terms"
                style={{ textDecoration: "none" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: PgColors.bluepg[300] }}
                >
                  Algemene Voorwaarden
                </Typography>
              </NavLink>
            </Grid>
            <Grid item xs={12}>
              {/* <NavLink
                className="nav-link"
                to="/terms"
                style={{ textDecoration: "none" }}
              > */}
              <Typography
                variant="subtitle1"
                style={{ color: PgColors.bluepg[300] }}
              >
                Opzeggen
              </Typography>
              {/* </NavLink> */}
            </Grid>
            <Grid item xs={12} marginTop={4}>
              <Typography variant="h6" style={{ color: PgColors.bluepg[300] }}>
                Social Media
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <a
                href="https://www.instagram.com/personal_gymnastics/"
                target="_blank"
              >
                <InstagramIcon sx={{ color: PgColors.bluepg[300] }} />
              </a>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: PgColors.redpg[200],
          borderBottom: 2,
          borderColor: "#000000",
        }}
      >
        <Grid container mb={2}>
          <Grid item xs={12} mt={2} justifyContent="center">
            <Typography
              variant="subtitle1"
              style={{ color: PgColors.white }}
              align="center"
            >
              2023 By Josine Dekker | Personal Gymnastics
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
