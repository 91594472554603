export const PgColors = {
  blue: {
    100: "#BFE7F2",
    200: "#9FC6D1",
    300: "#53A5BC",
    400: "#3D7585",
    500: "#2F5E6D",
  },
  grey: {
    50: "#F4EFEF",
    100: "#C1BDBD",
    200: "#8F8F8F",
    300: "#696969",
    400: "#525050",
    500: "#2E2E2E",
  },
  pink: {
    50: "#FFE9E9",
    100: "#EDCBCB",
    200: "#D4A7A7",
    300: "#B18B8B",
    400: "#8A6D6D",
    500: "#4F4141",
  },
  taupe: {
    50: "#FFF8F5",
    100: "#FFEBE4",
    200: "#F4D1C6",
    300: "#D4B2A7",
    400: "#A88A80",
    500: "#796058",
  },
  greenpg: {
    100: "#edf6e9",
    200: "#c2d3bb",
    300: "#b1c8a8",
    400: "#93af88",
    500: "#617b56",
  },
  pinkpg: {
    100: "#f8ecea",
    200: "#e6ceca",
    300: "#cfb1ac",
    400: "#bb9791",
    500: "#9f7872",
  },
  yellowpg: {
    100: "#fde8c4",
    200: "#e6c998",
    300: "#cfb280",
    400: "#b69761",
    500: "#8b7040",
  },
  redpg: {
    50: "#e6b4b3",
    100: "#cd8988",
    200: "#9f5b5a",
    300: "#864746",
    400: "#7b3837",
    500: "#541d1c",
  },
  bluepg: {
    100: "#89acad",
    200: "#5e7f80",
    300: "#446364",
    400: "#314d4d",
    500: "#192b2b",
  },
  white: "#FFFFFF",
};
