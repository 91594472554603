import React, { useState } from "react";
import {
  Grid,
  Container,
  Card,
  CardMedia,
  CardContent,
  Box,
  Typography,
  Alert,
  Paper,
  TextField,
  Snackbar,
  Button,
} from "@mui/material";
import { useLogin } from "../../hooks/useLogin";
import { Header } from "../../components/landingPage/Header";
import { useNavigate } from "react-router-dom";
import { PgColors } from "../../configs/colors";

export const Login = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { error, login } = useLogin();
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    const loginSuccessful = await login(email, password);
    if (loginSuccessful) {
      navigate("/dashboard/home");
    } else {
      setOpenError(true);
    }
    setEmail("");
    setPassword("");
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setOpenError(false);
  };

  return (
    <>
      <Header />

      <div
        style={{
          backgroundColor: PgColors.bluepg[100],
          height: "100vh",
        }}
      >
        <Container>
          <Grid container display="flex" justifyContent="center">
            <Grid
              item
              md={10}
              sx={{ marginTop: { xs: 4, lg: 8, xl: 10 } }}
              marginBottom={20}
              display="flex"
              justifyContent="center"
            >
              <Paper
                elevation={2}
                sx={{
                  minWidth: { xs: 360, sm: 560, md: 800, xl: 1000 },
                  minHeight: { xs: 400, md: 500, lg: 525, xl: 600 },
                }}
              >
                <form onSubmit={handleSubmit}>
                  <Grid container>
                    <Grid item md={4} display={{ xs: "none", md: "flex" }}>
                      <Box
                        component="img"
                        alt="symbol2"
                        src="../images/PGLogin.png"
                        sx={{ maxHeight: { md: 500, lg: 525, xl: 600 } }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={8}
                      sx={{ marginTop: { xs: 4, md: 2 } }}
                    >
                      <Typography
                        variant="h3"
                        style={{
                          color: PgColors.redpg[200],
                          fontWeight: 500,
                        }}
                        textAlign="center"
                        sx={{ fontSize: { xs: 36, md: 40, lg: 50 } }}
                      >
                        Login
                      </Typography>
                    </Grid>
                  </Grid>
                  <Container>
                    <Grid container sx={{ marginTop: { xs: 4, md: -50 } }}>
                      <Grid
                        item
                        md={6}
                        xl={5}
                        display={{ xs: "none", md: "flex" }}
                      ></Grid>
                      <Grid item xs={12} md={6} xl={7}>
                        <Typography variant="body1">Email adres:</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        md={6}
                        xl={5}
                        display={{ xs: "none", md: "flex" }}
                      ></Grid>
                      <Grid item xs={12} md={6} xl={7}>
                        <TextField
                          label="Email"
                          fullWidth
                          type="email"
                          variant="outlined"
                          onChange={(event) => setEmail(event.target.value)}
                          value={email}
                        ></TextField>
                      </Grid>
                    </Grid>
                    <Grid container marginTop={2}>
                      <Grid
                        item
                        md={6}
                        xl={5}
                        display={{ xs: "none", md: "flex" }}
                      ></Grid>
                      <Grid item xs={12} md={6} xl={7}>
                        <Typography variant="body1">Wachtwoord:</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        md={6}
                        xl={5}
                        display={{ xs: "none", md: "flex" }}
                      ></Grid>
                      <Grid item xs={12} md={6} xl={7}>
                        <TextField
                          label="Wachtwoord"
                          fullWidth
                          type="password"
                          variant="outlined"
                          onChange={(event) => setPassword(event.target.value)}
                          value={password}
                        ></TextField>
                      </Grid>
                    </Grid>

                    <Grid container marginTop={2}>
                      <Grid
                        item
                        md={6}
                        xl={5}
                        display={{ xs: "none", md: "flex" }}
                      ></Grid>
                      <Grid item xs={12} md={6} xl={7}>
                        <Button
                          type="submit"
                          variant="contained"
                          size="large"
                          style={{
                            backgroundColor: PgColors.redpg[200],
                          }}
                        >
                          Inloggen
                        </Button>
                      </Grid>
                    </Grid>
                  </Container>
                </form>
              </Paper>
            </Grid>
          </Grid>

          <Snackbar
            open={openError}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ color: PgColors.redpg[200] }}
            >
              Error
            </Alert>
          </Snackbar>
        </Container>
      </div>
    </>
  );
};
