import React, { useState } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import {
  Container,
  Grid,
  Typography,
  Checkbox,
  Paper,
  TextField,
  Button,
  Modal,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
  Divider,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { PgColors } from "../../../../configs/colors";
import { EditDScoreGymnast } from "./EditDscoreGymnast";
import { EditDScoreCoach } from "./EditDscoreCoach";

export const DScoresListCoach = ({ dScores }: any) => {
  const { user } = useAuthContext();
  const { id } = useParams();

  const toggleComplete1 = async (dScore: any) => {
    await updateDoc(doc(db, `MDScore/${id}/userDScore/${dScore.id}`), {
      s1: !dScore.s1,
    });
  };

  const toggleComplete2 = async (dScore: any) => {
    await updateDoc(doc(db, `MDScore/${id}/userDScore/${dScore.id}`), {
      s2: !dScore.s2,
    });
  };

  const toggleComplete3 = async (dScore: any) => {
    await updateDoc(doc(db, `MDScore/${id}/userDScore/${dScore.id}`), {
      s3: !dScore.s3,
    });
  };

  const toggleComplete4 = async (dScore: any) => {
    await updateDoc(doc(db, `MDScore/${id}/userDScore/${dScore.id}`), {
      s4: !dScore.s4,
    });
  };

  const handleDelete = async (dScore: any) => {
    const ref = doc(db, `MDScore/${id}/userDScore/${dScore.id}`);
    await deleteDoc(ref);
  };

  return (
    <>
      {dScores.map((dScore: any) => (
        <Container key={dScore.id}>
          <Grid container display={{ xs: "none", md: "flex" }}>
            <Grid item xs={4} md={2}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "white",
                    fontSize: 20,
                  }}
                  align="center"
                >
                  {dScore.date}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4} md={3}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                {dScore.ageCategory}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4} md={2}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                 Niveau: {dScore.level}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} md={2}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  Divisie: {dScore.division}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} md={3}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  {dScore.apparatus}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "none", md: "flex" }}>
            <Grid item xs={12} md={12}>
              <Paper
                sx={{
                  backgroundColor: PgColors.bluepg[100],
                }}
              >
                <Container>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "white",
                      fontSize: 20,
                      whiteSpace: "pre-line",
                    }}
                  >
                    {dScore.routine}
                  </Typography>
                </Container>
              </Paper>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            display={{ xs: "none", md: "flex" }}
          >
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  SE
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Checkbox
                checked={dScore.s1}
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 28,
                    color: PgColors.bluepg[200],
                  },
                }}
                onClick={() => toggleComplete1(dScore)}
              />
            </Grid>
            <Grid item md={1}>
              <Checkbox
                checked={dScore.s2}
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 28,
                    color: PgColors.bluepg[200],
                  },
                }}
                onClick={() => toggleComplete2(dScore)}
              />
            </Grid>
            <Grid item md={1}>
              <Checkbox
                checked={dScore.s3}
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 28,
                    color: PgColors.bluepg[200],
                  },
                }}
                onClick={() => toggleComplete3(dScore)}
              />
            </Grid>
            <Grid item md={1}>
              <Checkbox
                checked={dScore.s4}
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 28,
                    color: PgColors.bluepg[200],
                  },
                }}
                onClick={() => toggleComplete4(dScore)}
              />
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  TA
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  A
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  B
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  C
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  D
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  E
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "none", md: "flex" }}>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  MW
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
              >
                {" "}
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  {dScore.mw}
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  SE
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
              >
                {" "}
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  {dScore.se}
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  MW
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
              >
                {" "}
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  {dScore.acroTA}
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
              >
                {" "}
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  {dScore.acroA}
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
              >
                {" "}
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  {dScore.acroB}
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
              >
                {" "}
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  {dScore.acroC}
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
              >
                {" "}
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  {dScore.acroD}
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  {dScore.acroE}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "none", md: "flex" }}>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  VW
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  {dScore.vw}
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  D Score
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
              >
                {" "}
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  {dScore.dScore}
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  Bonus
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
              >
                {" "}
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  {dScore.dansTA}
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
              >
                {" "}
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  {dScore.dansA}
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
              >
                {" "}
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  {dScore.dansB}
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
              >
                {" "}
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  {dScore.dansC}
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
              >
                {" "}
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  {dScore.dansD}
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
              >
                {" "}
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  {dScore.dansE}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container mb={4} display={{ xs: "none", md: "flex" }}>
            <Grid item md={1}>
              <EditDScoreCoach
                path={`MDScore/${id}/userDScore/${dScore.id}`}
              />
            </Grid>
            <Grid item md={1}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                size="large"
                onClick={() => handleDelete(dScore)}
                sx={{ width: { lg: 55, xl: 75 }, height: 35 }}
              >
                <DeleteOutlinedIcon />
              </Button>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  Neutraal
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 35 }}
              >
                {" "}
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  {dScore.nAftrek}
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}></Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={4}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  {dScore.date}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  {dScore.ageCategory}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                 Niveau:  {dScore.level}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Divisie: {dScore.division}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  {dScore.apparatus}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper style={{ backgroundColor: PgColors.bluepg[100] }}>
                <Container>
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", whiteSpace: "pre-line" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  >
                    {dScore.routine}
                  </Typography>
                </Container>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={2}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  SE
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={1} mt={-1.5}>
              <Checkbox
                checked={dScore.s1}
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 28,
                    color: PgColors.bluepg[200],
                  },
                }}
                onClick={() => toggleComplete1(dScore)}
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1} mt={-1.5}>
              <Checkbox
                checked={dScore.s2}
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 28,
                    color: PgColors.bluepg[200],
                  },
                }}
                onClick={() => toggleComplete2(dScore)}
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1} mt={-1.5}>
              <Checkbox
                checked={dScore.s3}
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 28,
                    color: PgColors.bluepg[200],
                  },
                }}
                onClick={() => toggleComplete3(dScore)}
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1} mt={-1.5}>
              <Checkbox
                checked={dScore.s4}
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 28,
                    color: PgColors.bluepg[200],
                  },
                }}
                onClick={() => toggleComplete4(dScore)}
              />
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }} mt={-1}>
            <Grid item xs={2}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  TA
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  A
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  B
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  C
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  D
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  E
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }} mt={0}>
            <Grid item xs={12}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  MW
                </Typography>
              </Paper>
            </Grid>
          </Grid>

          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={2}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  {dScore.acroTA}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  {dScore.acroA}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  {dScore.acroB}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  {dScore.acroC}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  {dScore.acroD}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  {dScore.acroE}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }} mt={0}>
            <Grid item xs={12}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Bonus
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={2}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  {dScore.dansTA}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  {dScore.dansA}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  {dScore.dansB}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  {dScore.dansC}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  {dScore.dansD}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  {dScore.dansE}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        
 
          <Grid container display={{ xs: "flex", md: "none" }} mt={0}>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  SE
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  {dScore.se}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  MW
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  {dScore.mw}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }} mt={0}>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  D-score
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  {dScore.dScore}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  N aftrek
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.bluepg[100], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  {dScore.nAftrek}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }} mt={0} mb={2}>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <EditDScoreCoach
                path={`MDScore/${id}/userDScore/${dScore.id}`}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                size="large"
                onClick={() => handleDelete(dScore)}
                sx={{ width: 75, height: 35 }}
              >
                <DeleteOutlinedIcon />
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Container>
      ))}
    </>
  );
};
