import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import { Grid } from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { AddDScore2 } from "../D-Score/AddDScore2";
import { AddDScoreGymnast } from "./AddDScoreGymnast";
import { DScoresListGymnast } from "./DScoresListGymnast";
// import { AddDScore2 } from "./AddDScore2";
// import { DScoresList2 } from "./DScoresList2";

export const DScorePageGymnast = () => {
  const { authIsReady, user } = useAuthContext();
  const { id } = useParams();
  const [dScoresList, setDScoresList] = useState([]);

  useEffect(() => {
    if (authIsReady && user) {
      const ref = query(
        collection(db, `MDScore/${user.uid}/userDScore`),
        orderBy("createdAt", "desc")
      );

      const unsub = onSnapshot(ref, (snapshot) => {
        let results: any = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });
        setDScoresList(results);
      });
      return () => unsub();
    }
  }, [authIsReady, user]);

  if (!authIsReady) {
    return <p>Loading...</p>;
  }


  return (
    <>
      <Grid container mt={2}>
        <AddDScoreGymnast />
      </Grid>
      <Grid container mt={4}>
        {dScoresList && <DScoresListGymnast dScores={dScoresList} />}
      </Grid>
    </>
  );
};
