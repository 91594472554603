import React, { useState } from "react";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import AddIcon from "@mui/icons-material/Add";
import {
  Container,
  Grid,
  TextField,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { PgColors } from "../../../../configs/colors";

export const AddDScore = () => {
  const { id } = useParams();
  const { user } = useAuthContext();
  const [date, setDate] = useState("");
  const [ageCategory, setAgeCategory] = useState("");
  const [level, setLevel] = useState("");
  const [division, setDivision] = useState("");
  const [apparatus, setApparatus] = useState("");
  const [routine, setRoutine] = useState("");
  const [mw, setMw] = useState("");
  const [se, setSe] = useState("");
  const [vw, setVw] = useState("");
  const [dScore, setDScore] = useState("");
  const [acroTA, setAcroTA] = useState("");
  const [acroA, setAcroA] = useState("");
  const [acroB, setAcroB] = useState("");
  const [acroC, setAcroC] = useState("");
  const [acroD, setAcroD] = useState("");
  const [acroE, setAcroE] = useState("");
  const [acroF, setAcroF] = useState("");
  const [dansTA, setDansTA] = useState("");
  const [dansA, setDansA] = useState("");
  const [dansB, setDansB] = useState("");
  const [dansC, setDansC] = useState("");
  const [dansD, setDansD] = useState("");
  const [dansE, setDansE] = useState("");
  const [dansF, setDansF] = useState("");
  const [overTA, setOverTA] = useState("");
  const [overA, setOverA] = useState("");
  const [overB, setOverB] = useState("");
  const [overC, setOverC] = useState("");
  const [overD, setOverD] = useState("");
  const [overE, setOverE] = useState("");
  const [overF, setOverF] = useState("");
  const [nAftrek, setNAftrek] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `DScore/${id}/userDScore`);

    await addDoc(ref, {
      date: date,
      ageCategory: ageCategory,
      level: level,
      division: division,
      apparatus: apparatus,
      routine: routine,
      s1: false,
      s2: false,
      s3: false,
      s4: false,
      mw: mw,
      se: se,
      vw: vw,
      dScore: dScore,
      acroTA: acroTA,
      acroA: acroA,
      acroB: acroB,
      acroC: acroC,
      acroD: acroD,
      acroE: acroE,
      acroF: acroF,
      dansTA: dansTA,
      dansA: dansA,
      dansB: dansB,
      dansC: dansC,
      dansD: dansD,
      dansE: dansE,
      dansF: dansF,
      overTA: overTA,
      overA: overA,
      overB: overB,
      overC: overC,
      overD: overD,
      overE: overE,
      overF: overF,
      nAftrek: nAftrek,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setDate("");
    setAgeCategory("");
    setLevel("");
    setDivision("");
    setApparatus("");
    setRoutine("");
    setSe("");
    setMw("");
    setVw("");
    setDScore("");
    setAcroTA("");
    setAcroA("");
    setAcroB("");
    setAcroC("");
    setAcroD("");
    setAcroE("");
    setAcroF("");
    setDansTA("");
    setDansA("");
    setDansB("");
    setDansC("");
    setDansD("");
    setDansE("");
    setDansF("");
    setOverTA("");
    setOverA("");
    setOverB("");
    setOverC("");
    setOverD("");
    setOverE("");
    setOverF("");
    setNAftrek("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={4} md={2}>
              <TextField
                label="Datum"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={date}
                onChange={(event) => setDate(event.target.value)}
              />
            </Grid>
            <Grid item xs={4} md={3}>
              <TextField
                label="Leeftijd Categorie"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={ageCategory}
                onChange={(event) => setAgeCategory(event.target.value)}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <TextField
                label="Niveau/Sup"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={level}
                onChange={(event) => setLevel(event.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <TextField
                label="Divisie"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={division}
                onChange={(event) => setDivision(event.target.value)}
              />
            </Grid>

            <Grid item xs={6} md={3}>
              <TextField
                label="Toestel"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={apparatus}
                onChange={(event) => setApparatus(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                label="Oefening"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                multiline
                rows={8}
                fullWidth
                value={routine}
                onChange={(event) => setRoutine(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container display={{ xs: "none", md: "flex" }}>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  SE
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  1
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  2
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  3
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  4
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  TA
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  A
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  B
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  C
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  D
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  E
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "none", md: "flex" }}>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 40 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  MW
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={mw}
                onChange={(event) => setMw(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 40 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  SE
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={se}
                onChange={(event) => setSe(event.target.value)}
              />
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 40 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  Acro
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={acroTA}
                onChange={(event) => setAcroTA(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={acroA}
                onChange={(event) => setAcroA(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={acroB}
                onChange={(event) => setAcroB(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={acroC}
                onChange={(event) => setAcroC(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={acroD}
                onChange={(event) => setAcroD(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={acroE}
                onChange={(event) => setAcroE(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container display={{ xs: "none", md: "flex" }}>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 40 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  VW
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={vw}
                onChange={(event) => setVw(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 40 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { md: 14, lg: 20 }, pt: { md: 1, lg: 0 } }}
                  align="center"
                >
                  D Score
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={dScore}
                onChange={(event) => setDScore(event.target.value)}
              />
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 40 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { md: 14, lg: 20 }, pt: { md: 1, lg: 0 } }}
                  align="center"
                >
                  Dans
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={dansTA}
                onChange={(event) => setDansTA(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={dansA}
                onChange={(event) => setDansA(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={dansB}
                onChange={(event) => setDansB(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={dansC}
                onChange={(event) => setDansC(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={dansD}
                onChange={(event) => setDansD(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={dansE}
                onChange={(event) => setDansE(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container display={{ xs: "none", md: "flex" }}>
            <Grid item md={1}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                size="large"
                type="submit"
                sx={{ height: 40, width: { xs: 64, lg: 92 } }}
              >
                <AddIcon />
              </Button>
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 40 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { md: 14, lg: 20 }, pt: { md: 1, lg: 0 } }}
                  align="center"
                >
                  Neutraal
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={nAftrek}
                onChange={(event) => setNAftrek(event.target.value)}
              />
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 40 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { md: 14, lg: 20 }, pt: { md: 1, lg: 0 } }}
                  align="center"
                >
                  Over
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={overTA}
                onChange={(event) => setOverTA(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={overA}
                onChange={(event) => setOverA(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={overB}
                onChange={(event) => setOverB(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={overC}
                onChange={(event) => setOverC(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={overD}
                onChange={(event) => setOverD(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={overE}
                onChange={(event) => setOverE(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={12}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Acro
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                label="TA"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10 },
                  },
                }}
                variant="outlined"
                fullWidth
                value={acroTA}
                onChange={(event) => setAcroTA(event.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                label="A"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10 },
                  },
                }}
                variant="outlined"
                fullWidth
                value={acroA}
                onChange={(event) => setAcroA(event.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                label="B"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10 },
                  },
                }}
                variant="outlined"
                fullWidth
                value={acroB}
                onChange={(event) => setAcroB(event.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                label="C"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10 },
                  },
                }}
                variant="outlined"
                fullWidth
                value={acroC}
                onChange={(event) => setAcroC(event.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                label="D"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10 },
                  },
                }}
                variant="outlined"
                fullWidth
                value={acroD}
                onChange={(event) => setAcroD(event.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                label="E"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10 },
                  },
                }}
                variant="outlined"
                fullWidth
                value={acroE}
                onChange={(event) => setAcroE(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={12}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Dans
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                label="TA"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10 },
                  },
                }}
                variant="outlined"
                fullWidth
                value={dansTA}
                onChange={(event) => setDansTA(event.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                label="A"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10 },
                  },
                }}
                variant="outlined"
                fullWidth
                value={dansA}
                onChange={(event) => setDansA(event.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                label="B"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10 },
                  },
                }}
                variant="outlined"
                fullWidth
                value={dansB}
                onChange={(event) => setDansB(event.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                label="C"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10 },
                  },
                }}
                variant="outlined"
                fullWidth
                value={dansC}
                onChange={(event) => setDansC(event.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                label="D"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10 },
                  },
                }}
                variant="outlined"
                fullWidth
                value={dansD}
                onChange={(event) => setDansD(event.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                label="E"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10 },
                  },
                }}
                variant="outlined"
                fullWidth
                value={dansE}
                onChange={(event) => setDansE(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={12}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Over
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                label="TA"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10 },
                  },
                }}
                variant="outlined"
                fullWidth
                value={overTA}
                onChange={(event) => setOverTA(event.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                label="A"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10 },
                  },
                }}
                variant="outlined"
                fullWidth
                value={overA}
                onChange={(event) => setOverA(event.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                label="B"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10 },
                  },
                }}
                variant="outlined"
                fullWidth
                value={overB}
                onChange={(event) => setOverB(event.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                label="C"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10 },
                  },
                }}
                variant="outlined"
                fullWidth
                value={overC}
                onChange={(event) => setOverC(event.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                label="D"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10 },
                  },
                }}
                variant="outlined"
                fullWidth
                value={overD}
                onChange={(event) => setOverD(event.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                label="E"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10 },
                  },
                }}
                variant="outlined"
                fullWidth
                value={overE}
                onChange={(event) => setOverE(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                label="MW"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10 },
                  },
                }}
                variant="outlined"
                fullWidth
                value={mw}
                onChange={(event) => setMw(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                label="SE"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10 },
                  },
                }}
                variant="outlined"
                fullWidth
                value={se}
                onChange={(event) => setSe(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                label="VW"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10 },
                  },
                }}
                variant="outlined"
                fullWidth
                value={vw}
                onChange={(event) => setVw(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                label="D-score"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10 },
                  },
                }}
                variant="outlined"
                fullWidth
                value={dScore}
                onChange={(event) => setDScore(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                label="Neutraal"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10 },
                  },
                }}
                variant="outlined"
                fullWidth
                value={nAftrek}
                onChange={(event) => setNAftrek(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                size="large"
                type="submit"
                sx={{ height: 55 }}
              >
                <AddIcon />
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};
